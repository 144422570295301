import React from "react"

// Libraries
import styled from "styled-components"

// Components
import Container from "components/container"
import DownloadsSection from "components/downloads-sections"

// Training Materials
import STIEmailCommunication from "assets/downloads/visby-university/sexual-health-test/VM-STI-Internal Comm Template- PL-000130_B.pdf"

const StyledContainer = styled(Container)`
  max-width: 1100px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 20px;

`

const BillingSection = props => {
	return (
		<DownloadsSection
			id="sti-billing"
			viewAllowed={props.viewAllowed}
			data-filter="sexual-health" data-filter-type="devices" data-section="billing"
		>
			<StyledContainer>
				<div className="block__content">
					<h3>Coding and Operational Resources</h3>
				</div>
				<div className="block">
					<div className="block__material">
						<ul className="downloadsList">
							<li>
								<a href="https://www.codemap.com/visby/?state=00000&locality=null" target="_blank" rel="noopener noreferrer">CPT Billing-Code Map</a>
							</li>
							<li>
								<a href={STIEmailCommunication} target="_blank" rel="noopener noreferrer">Internal Communication Announcement-Visby PCR Test</a>
							</li>
						</ul>
					</div>
					</div>
					<div className="block">
					<div className="block__material">
							<h4>Auxiliary Resources available from the <a href="https://www.ama-assn.org/" className="orangeLink" target="_blank" rel="noopener noreferrer">American Medical Association (AMA)</a></h4>
							<ul className="downloadsList">
							<li>
								<a href="https://www.ama-assn.org/system/files/2019-06/cpt-office-prolonged-svs-code-changes.pdf" target="_blank" rel="noopener noreferrer">AMA- E&M Coding Guidelines*</a>
								<span className="note">*please see page 13 for moderate-level validation 99204 and 99214</span>
							</li>
						</ul>
						</div>

				</div>
			</StyledContainer>
		</DownloadsSection>
	)
}

export default BillingSection
